// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/webpack-config-single-spa/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logo {
    vertical-align: baseline;
    height: 15px;
}


.bny-light-background {
    background-color: rgb(227, 235, 237);
}`, "",{"version":3,"sources":["webpack://./src/styles/navbar.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;IACxB,YAAY;AAChB;;;AAGA;IACI,oCAAoC;AACxC","sourcesContent":[".logo {\n    vertical-align: baseline;\n    height: 15px;\n}\n\n\n.bny-light-background {\n    background-color: rgb(227, 235, 237);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
