import React from "react";
import { Container, Nav, Navbar, Image } from "react-bootstrap";
import LightThemeLogo from "../img/bny_logo_darkteal.svg";
import { Link } from "react-router-dom";
import "../styles/navbar.css";

function ArenaNavbar(props) {

    const paths = props.props.paths.map((route) => 
        <Nav.Link key={route.pathName} as={Link} to={route.path}>{route.pathName}</Nav.Link>
    );
    return (
        <Navbar className="bny-light-background" data-bs-theme="light">
            <Container>
                <Navbar.Brand>
                    <Image className="logo" src={LightThemeLogo} />
                    {' '} Arena
                </Navbar.Brand>
                <Nav className="ms-auto">
                    <Nav.Link key="home" as={Link} to="/home">Home</Nav.Link>
                    {paths}
                </Nav>
            </Container>
        </Navbar>
    );
}

export default ArenaNavbar;