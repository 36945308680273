import React from "react";
import Navbar from "./components/ArenaNavbar";
import Home from "./components/home"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';


function App(props) {

    const paths = props.paths

    const routes = props.paths.map((route) =>
        <Route key={route.pathName} path={route.path} element={<div/>}></Route>
    );

    return (
            <Router>
                <Navbar props={{paths}} />
                <Routes>
                    <Route key="Home" path="/home" element={<Home />} />
                    {routes}
                </Routes>
            </Router>
    )
}

export default App;